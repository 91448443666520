import { SiteMeasuringAgentPhase, SiteMeasuringState } from 'api/models/siteMeasuringModel'
import { AsyncHandlerLoadingState } from 'framework/lib/useAsyncHandler'
import { UserStatus } from 'api/models/userStatus'
import { ScaffoldAgentPhase, ScaffoldState } from '../api/models/scaffoldModel'

export default {

  appBar: {
    logout: 'Abmelden',
    requests: 'Anträge',
  },
  nav: {
    home: '',
    multiScaffoldEditor: '',
    scaffoldInquiries: 'Neuanträge',
    scaffolds: 'Gerüste',
    archive: 'nach Jahr',
    last12Months: 'letzten 12 Monate',
    siteMeasuring: 'Aufmaße',
    siteMeasuring2: 'Aufmaße',
    siteMeasuringInspection: 'Aufmaßkontrolle',
    siteMeasuringTemplates: 'Aufmaß-Templates',
    administration: 'Verwaltung',
    newScaffold: 'Gerüst bestellen',
    customers: 'Kunden',
    users: 'Benutzer',
    myProjects: 'Projekte',
    scaffolders: 'Gerüstbauer',
  },
  application: {
    archiveView: '{{name}} nach Jahr',
    new: 'Erstellen',
    operationUnit: 'Betrieb',
    operationUnits: 'Betriebe',
    customer: 'Kunde',
    company: 'Firma',
    customers: 'Kunden',
    building: 'Gebäude',
    buildings: 'Gebäude',
    project: 'Projekt',
    projects: 'Projekte',
    users: 'Benutzer',
    scaffoldInquiries: 'Neuanträge',
    scaffoldInquiriesCurrent: 'Gerüste',
    scaffoldInquiriesArchive: 'Gerüste',
    siteMeasuring: 'Aufmaße',
    siteMeasuringInspection: 'Aufmaßkontrolle',
    siteMeasuringTemplates: 'Aufmaß-Templates',
    scaffolder: 'Gerüstbauer',
    scaffolders: 'Gerüstbauer',
    constructions: 'Aufmaße',
  },
  imprint: {
    headline: 'Impressum',
    addressTitle: 'Kontakt',
    companyName: 'YNCORIS GmbH & Co. KG',
    addressLine: 'Industriestraße 300',
    location: '50354 Hürth',
    country: 'Deutschland',
    companyLeader: 'tbd',
    contactEmail: 'tbd@yncoris.com',
    ustId: 'Umsatzsteuer-ID: tbd',
  },
  cookieBanner: {
    cookies: 'Cookie',
    cookieText: 'Diese Webseite verwendet Cookies, um Ihre Anmeldung am System zu speichern.',
    dialogSubmitButton: 'Einstellung speichern',
    technicalNecessary: 'Technisch notwendige Cookies',
    acceptCookiesBeforeLoginIsPossible: 'Technisch notwendige Cookies akzeptieren, um den Login zu aktivieren.',
  },
  generic: {
    appName: 'Gerüstverwaltung',
    year: 'Jahr',
    dateFormat: 'dd.MM.yyyy',
    shortDateFormat: 'dd.MM.yy',
    dateTimeFormat: 'dd.MM.yyyy HH:mm',
    yearFormat: 'yyyy',
    dateTimeFormatMask: '__.__.____ __:__',
    required: 'Bitte ausfüllen',
    confused: 'Seltsames Verhalten bemerkt',
    new: 'Neu',
    back: 'Zurück',
    save: 'Speichern',
    archive: 'Archivieren',
    archived: 'Archiviert',
    dearchive: 'Archivieren aufheben',
    delete: 'Löschen',
    preview: 'Vorschau',
    check: 'Eingaben prüfen',
    abort: 'Abbrechen',
    draft: 'Zwischenspeichern',
    clone: 'Kopieren',
    yes: 'Ja',
    no: 'Nein',
    confirm: 'Bestätigen',
    close: 'Schließen',
    id: 'Id',
    preparingDownload: 'Download wird vorbereitet: {{name}}',
    fileTypeNotAccepted: 'Dateiformat nicht akzeptiert oder Datei zu groß',
    confirmDelete: 'Möchten Sie wirklich löschen? Bitte einmal bestätigen',
  },
  units: {
    metre: 'm',
    squareMetre: 'm²',
    piece: 'Stück',
    hour: 'Std',
    day: 'Tag',
    dayS: 'Tag(e)',
    euroPerHour: '€ / Std',
    euroPerDay: '€ / Tag',
  },
  dropzone: {
    dragFilesHereLine1: 'Verschlüsselte / Gesperrte Dateien können nicht verarbeitet werden.',
    dragFilesHereLine2: 'Einfach Datei hierhin ziehen oder hier klicken für das Auswahlmenü.',
    accept: 'Akzeptiere Dateitypen (max. 10MB):',
    uploadedFile: 'Ausgewählte Datei',

  },
  attachments: {
    save: 'Speichern',
    edit: 'Editieren',
    viewAndEdit: 'Ansehen / Editieren',
    downloadOriginal: 'Original herunterladen',
    downloadMarked: 'Herunterladen',
    'application/pdf': 'Markierungen vornehmen - PDF Hinweis: nur Seite 1 mit ggf. Qualitätsverlust. Andere Seiten gehen durchs bearbeiten verloren. Dieser Vorgang ist unwiderruflich.',
    'image/png': 'Markierungen vornehmen',
    'image/jpg': 'Markierungen vornehmen',
    'image/jpeg': 'Markierungen vornehmen',
  },
  imageInput: {
    hint: 'Einfach Elemente rüberziehen und verschieben. Einfacher Klick zum löschen im Bild',
    locationPinTooltip: 'Einfach rüber ziehen und platzieren. Zum Löschen klicken, zum verschieben Maus gedrückt halten',
    textFieldTooltip: 'Text eingeben, dann einfach rüber ziehen und platzieren. Zum Löschen klicken, zum verschieben Maus gedrückt halten',
    zoom: 'Zoom',
    fontSize: 'Schriftgröße',
    tooltipDraggable: 'Elemente auf Dokument ziehen und platzieren. Zum Löschen klicken oder zum Verschieben mit gedrückter Maustaste ziehen',
  },
  siteMeasuringInspection: {
    scaffoldInquiryNr: 'Gerüst-Nr: {{serial}}',
    scaffolderValue: 'Gerüstbauerangabe',
    overallAssessmentAccepted: 'Gesamtbeurteilung',
    correctLabel: '',
    notCorrect: 'Fehlerhaft',
    correct: 'Richtig',
    correction: 'Korrekturwert',
  },
  siteMeasuringTemplates: {
    tabs: 'Reiter',
    groups: 'Gruppen',
    fields: 'Felder',
    type: 'Feldtyp',
    text: 'Freitext',
    number: 'Ganze Zahl (z.B. 4)',
    float: 'Gleitkomma Zahl (z.B. 4,5)',
    multiline: 'Freitext (Mehrzeilig, groß)',
    shortName: 'Kürzel',
    fullName: 'Beschreibung (u.a. Tooltip)',
    fullNameTooltip: 'Wird z.B. als Tooltip angezeigt, wie dieser hier',
    invalidChar: '',
    unit: 'Einheit',
    unitTooltip: 'Am Besten max 3 Zeichen. Reduziert Größe des Eingabefeldes.',
    siteMeasuringAccountingFieldGroups: 'Abrechnungsfelder für Gerüstbauer',
    p1: 'P1',
    p2: 'P2',
    pkey: 'P-Schlüssel',
    bl: 'BL',
    la: 'LA',
    new: 'Hinzufügen',
    name: 'Name',
  },
  siteMeasuringState: {
    [SiteMeasuringState.DRAFT]: 'Zwischengespeichert',
    [SiteMeasuringState.SIGNED_BY_SCAFFOLDER]: 'Übermittelt durch Gerüstbauer',
    [SiteMeasuringState.FULLY_SIGNED]: 'Genehmigt',
    [SiteMeasuringState.REJECTED_BY_GDK]: 'Abgelehnt',
    [SiteMeasuringState.CANCELLED]: 'Storniert',
  },
  siteMeasuringAgentPhase: {
    [SiteMeasuringAgentPhase.GDK]: 'Yncoris',
    [SiteMeasuringAgentPhase.SCAFFOLDER]: 'Gerüstbauer',
    [SiteMeasuringAgentPhase.FULLY_SIGNED]: 'Abgeschlossen',
  },
  siteMeasuring: {
    // barrierTape: 'AB', // Absperrband
    // flashingLight: 'BL', // Blinklicht
    // dowelInConcrete: 'DB', // Dübel im Beton
    // planksShort: 'DK', // Dielen kurz bis 3m,
    // planksLong: 'DL', // Dielen lang bis 4,5m
    // dowelInMasonry: 'DM', // 'Dübel in Mauerwerk',
    // latticeGirders: 'GI', // Gitterträger
    // slopeLatticeGirders: 'HGI', // Hängegitterträger
    // slopeCouplings: 'HK', // Hängekupplung
    // couplings: 'K', // 'Kupplungen',
    // consoles: 'KO', // 'Konsolen',
    // moduleScaffoldMobile: 'MF', // Modulgerüst fahrbar
    // moduleSlopePlate: 'MHT', // Modulhängeteller
    // moduleConsoleShort: 'MKK', // Modulkonsolen kurz 0,3m-0,7m
    // moduleConsoleLong: 'MKL', // Modulkonsolen lang 1 m
    // modulePlate: 'MT', // Modulteller
    // webs: 'NE', // Netze
    // tarpaulinA: 'PAK', // Planen
    // tarpaulinE: 'PCE', // Planen
    // tarpaulinK: 'PCK', // Planen
    // wheelsLight: 'ROL',
    // wheelsHeavy: 'ROS',
    // fastScaffold07: 'SB07', // schnellbaugerüst 0,7
    // fastScaffold10: 'SB10',
    // bladeCoating2: 'SB2',
    // bladeCoating3: 'SB3',
    // warnStrips: 'WL',
    // swingDoor: 'SchwTür',
    setAccounted: 'Als abgerechnet markieren',
    cancelAccounted: 'Abgerechnet Markierung entfernen',
    createSiteMeasuringPdf: 'Aufmaß-Bericht erstellen',
    createSiteMeasuringInspectation: 'Aufmaßkontrolle erstellen',
    additionalServices: 'Zusatzleistungen',
    wage: 'Lohn',
    material: 'Material',
    rent: 'Leihgebühr',
    typeOfWork: 'Art der Arbeit',
    scaffoldInquiryNr: 'Gerüst-Nr: {{serial}}',
    scaffoldInquirySerial: 'Gerüst-Nr',
    signScaffolder: 'Bestätigen und an Yncoris übermitteln',
    signGdk: 'Bestätigen',
    signGdkAgain: 'Erneut bestätigen',
    gdkComment: 'Kommentar (Yncoris)',
    acceptedByGdk: '',
    accepted: 'Aufmaß akzeptiert',
    acceptedSiteMeasuringInspection: 'Aufmaß korrekt',
    declined: 'Aufmaß <b>nicht</b> akzeptiert',
    state: 'Status',
    agentPhase: 'Aktuelle Zuständigkeit',
    acceptedByGdkStatus: 'Ok',
    ok: 'Ok',
    notOk: 'Nicht Ok',
    accounted: 'Abgerechnet',
    scaffoldDetails: 'Gerüstdetails',
    scaffoldLength: 'Gerüstlänge',
    scaffoldHeight: 'Gerüsthöhe',
    scaffoldDepth: 'Gerüsttiefe',
    stageCount: 'Anzahl Bühnen',
    footPointHeight: 'Fußpunkthöhe',
    cancel: 'Stornieren',
    undoCancel: 'Stornierung aufheben',
    confirmCancellation: 'Aufmaß als storniert kennzeichnen.',
    // customer: 'Kunde',
    // scaffolder: 'Gerüstbauer',
    // building: 'Gebäude',
    // area: 'Bühne / Ebene',

  },
  error: {
    title: 'Es ist ein Fehler aufgetreten',
    description: 'Dies ist ungewöhnlich. Bitte prüfen Sie ob die Seite mit z.B. Google Translate übersetzt ist. Dies stellt eine Fehlerquelle dar.',
    reloadPage: 'Seite neu laden',
  },
  numberField: {
    noNumber: 'Keine Zahl',
    noValidFloat: 'Keine Gleitkommazahl (z.B. 5,4)',
    decimalSeparator: {
      from: ',',
      to: '.',
    },
  },
  alphanumericField: {
    invalidChar: 'Nicht alphanumerisch',
  },
  emailField: {
    email: 'E-Mail',
    noValidEmail: 'E-Mail ungültig (email@yncoris.com)',
    noEmail: 'Bitte E-Mail eingeben',
  },
  userNameField: {
    allowedChars: 'Erlaubte Zeichen: A-Z, a-z, 0-9, . , @',
  },
  select: {
    NoOptions: 'Keine Optionen',
    CreateMessage: 'Erstelle {{value}}',
  },
  datePicker: {
    noDate: 'Bitte Datum auswählen',
    noValidDate: 'Ungültiges Datum',
    placeholder: 'DD.MM.YYYY',
    clear: 'Zurücksetzen',
    cancel: 'Abbrechen',
    ok: 'Übernehmen',
    tooEarly: 'Datum ist früher als {{minDate}} ',
    noPastDate: 'Vergangenheit ist nicht erlaubt',
  },
  passwordField: {
    password: 'Passwort',
    passwordConfirmation: 'Passwort wiederholen',
    pleaseEnterPassword: 'Bitte Passwort eingeben',
    tooShort: 'Zu kurz',
    passwordDifferent: 'Passwörter unterschiedlich',
    needsSpecialChar: 'Sonderzeichen benötigt',
    needsNumber: 'Zahl benötigt',
    needsLowerCase: 'Kleinbuchstabe benötigt',
    needsUpperCase: 'Großbuchstabe benötigt',
    needsUniqueChars: 'Mindestens 4 unterschiedliche Zeichen',
  },
  autocomplete: {
    placeholder: 'auswählen',
    noOptions: 'Keine Optionen verfügbar',
  },
  requestAccount: {
    title: 'Zugang beantragen',
    firstName: 'Vorname(n)',
    lastName: 'Nachname',
    email: 'E-Mail',
    phoneNumber: 'Telefonnummer',
    password: 'Passwort',
    comparePassword: 'Passwort wiederholen',
    backToLogin: 'zum Login',
    successTitle: 'Nur noch ein Schritt',
    success: 'Zur Vervollständigung der Anfrage wurde eine E-Mail verschickt, diese bitte bestätigen.',
  },
  login: {
    email: 'E-Mail',
    password: 'Passwort',
    login: 'Anmelden',
    backToLogin: 'Zurück zum Login',
    registerRequest: 'Noch keinen Account?',
    resetPassword: 'Passwort vergessen?',
    requestReset: 'Zurücksetzen anfragen',
    reset: 'Zurücksetzen',
    activate: 'Account aktivieren',
    currentPassword: 'Aktuelles Passwort',
    newPassword: 'Neues Passwort',
    comparePassword: 'Passwort wiederholen',
    emailConfirmed: 'E-Mail wurde bestätigt',
    passwordChangedSuccessful: 'Passwort wurde erfolgreich geändert',
    accountRequestSubmitted: 'Bitte Postfach prüfen und E-Mail bestätigen',
    accountActivationSuccessful: 'Account wurde aktiviert. Sie wurden automatisch angemeldet',
    requestResetPasswordSuccessful: 'Sofern die E-Mail registriert ist, erhältst du in Kürze weitere Anweisungen per E-Mail. Bitte Postfach prüfen.',
    requestAccountSuccessful: 'Dein Account wurde beantragt. Sobald dieser genehmigt wurde wirst du informiert',
    changePassword: 'Passwort ändern',
    resetPasswordSuccessful: 'Passwort erfolgreich zurückgesetzt - bitte nun anmelden',
  },
  footer: {
    contact: 'Kontaktieren',
    imprint: 'Impressum',
    dataPrivacyHtml: 'Datenschutz&shy;erklärung',
    dataPrivacy: 'Datenschutzerklärung',
    aboutUs: 'Über uns',
    allRightsReserved: 'Alle Rechte vorbehalten',
    agb: 'AGB',
  },
  api: {
    fileMimeTypeMismatch: 'Dateiendung entspricht nicht dem Dateiformat. Anhang wurde nicht gespeichert.',
    virusCheckFailed: 'Datei hat Virusüberprüfung nicht bestanden. Anhang wurde nicht gespeichert.',
    networkError: 'Netzwerkfehler. Bitte Internetverbindung prüfen und ggf. Support kontaktieren. ',
    requestId: 'Id',
    validationError: 'Formular ungültig.',
    generic: 'Fehler: bitte nochmal versuchen',
    NOT_LOGGED_IN: 'Nicht eingeloggt, bitte zuerst anmelden.',
    NO_RESULTS: 'Keine Ergebnisse gefunden',
    TODO: 'Noch nicht implementiert',
    corruptFile: 'Datei fehlerhaft/nicht lesbar',
    NO_PERMISSION: 'Keine Berechtigung.',
    PDF_REQUEST_TOO_LARGE: 'Zu viele Anfragen oder zu viele Dateien. Bitte Dateien reduzieren oder später erneut versuchen.',
    BAD_REQUEST: 'Anfrage fehlerhaft',
    INVALID_CHANGE_PASSWORD: 'Fehlerhafte Anfrage - bitte aktuelles Passwort überprüfen.',
    ACCOUNT_NOT_ACTIVE: 'Account ist nicht aktiv. Bitte Support kontaktieren.',
    ENROLL_FAILED: 'Registrierung fehlgeschlagen.',
    INVALID_LOGIN: 'User/Passwort Kombination falsch',
    INVALID_TOKEN: 'Passwort zurücksetzen abgelaufen, bitte erneut zurücksetzen.',
    INVALID_ENROLL_TOKEN: 'Account aktivieren abgelaufen, bitte Passwort zurücksetzen.',
    INTERNAL_ERROR: 'Interner Fehler - bitte Seite neu laden, dann versuchen und ggf Support kontaktieren.',
    DUPLICATE_ITEMS: 'Dieser Wert existiert schon',
    DELETE_FAILED_REFERENCE_FOUND: 'Kann nicht gelöscht werden, weil es verwendet wird. Bitte archivieren',
    invalidLogin: 'User/Passwort Kombination falsch',
    notReachable: 'Backend nicht erreichbar. Bitte Support kontaktieren',
    saveSuccess: 'Erfolgreich gespeichert',
    archiveSuccess: 'Erfolgreich archiviert',
    deleteSuccessful: 'Erfolgreich gelöscht',
    SCAFFOLD_REVERT_CANCELED_DATE_IMPOSSIBLE: 'Status kann nicht zurückgesetzt werden. Bereits abgebaut',
    SCAFFOLD_DELETE_IMPOSSIBLE: 'Gerüst kann nicht gelöscht werden, bereits fertiggestellt.',
    SCAFFOLD_RESET_CLIENT_WORKFLOW_NOT_POSSIBLE_ANYMORE: 'Workflow kann nicht mehr zurückgesetzt werden. Bitte Yncoris kontaktieren.',
  },
  user: {
    greeting: 'Hallo {{firstName}} {{lastName}},',
    lastLogin: 'Ihre letzte Anmeldung: ',
    logout: 'Abmelden',
    changePassword: 'Mein Passwort ändern',
    changeMyPassword: 'Mein Passwort ändern',
    currentPassword: 'Aktuelles Passwort',
    newPassword: 'Neues Passwort',
    comparePassword: 'Neues Passwort wiederholen',
    submitChangePassword: 'Absenden',
  },
  confirmEmail: {
    'account-request': 'Ihre Account-Anfrage ist nun eingegangen und wartet auf Freischaltung. Sie werden per E-Mail über die Freischaltung informiert.',
    confirm: 'Ihre E-Mail wurde erfolgreich bestätigt.',
    loading: 'Entweder ist Ihre Anfrage bereits bestätigt oder die Anfrage war fehlerhaft.',
    [AsyncHandlerLoadingState.Loading]: 'Wird bearbeitet. Bitte warten.',
    [AsyncHandlerLoadingState.Initial]: 'Wird bearbeitet. Bitte warten.',
    [AsyncHandlerLoadingState.Failed]: 'Entweder ist Ihre Anfrage bereits bestätigt oder die Anfrage war fehlerhaft.',
    [AsyncHandlerLoadingState.Success]: 'Bestätigt',
    goToStartPage: 'Zum Login',
  },
  scaffoldInquiryAttachments: {
    name: 'Name',
    uploader: 'Ursprünglich hochgeladen von',
    lastModified: 'Zuletzt geändert',
    createdOn: 'Hochgeladen',
    image: 'Hilfreiche Informationen',
    hint: 'Jederzeit änderbar - nicht Teil der Unterschrift / Bestellprozesses',
  },
  scaffolds: {
    lastModified: '',
    rowNumber: 'Nr',
    constructionDateRequiredForSiteMeasuring: 'Aufbaudatum ist erforderlich um ein Aufmaß zu erstellen.',
    revertCanceledDate: 'Gerüst reaktivieren',
    restartWorkflow: 'Genehmigungsworkflow zurücksetzen',
    restartWorkflowDescription: 'Workflow wird vollständig zurückgesetzt und der Antrag zurück zum Anforderer geschoben.',
    actions: 'Aktionen',
    serialNr: 'Schein-Nr',
    customerId: 'Kunde',
    operationUnitId: 'Betrieb',
    buildingId: 'Gebäude',
    area: 'Bühne / Ebene',
    orderedByUserId: 'Name Anforderer',
    orderedByName: 'Name Anforderer',
    phone: 'Telefonnr.',
    email: 'E-Mail',
    orderNr: 'Bestell-Nr',
    preferredConstructionDate: 'Wunsch-Aufbau',
    preferredDeconstructionDate: 'Wunsch-Abbau',
    scaffoldReferenceId: 'Referenz Gerüst-Nr',
    adHocOrder: 'Adhoc-Bestellung',
    permitRequired: 'Erlaubnisschein',
    description: 'Bemerkung/ Art der Arbeiten',
    newScaffold: 'Neubau',
    changeScaffold: 'Umbau',
    type: 'Art',
    new: 'Neu',
    pleaseSaveItem: 'Für Anhänge muss der Antrag zwischengespeichert werden',
    projectId: 'Projekt',
    permitRequiredPlaceholder: 'auswählen',
    necessary: 'erforderlich',
    unnecessary: 'nicht erforderlich',
    state: 'Status',
    NEVER: 'Nein',
    ALWAYS: 'Immer',
    NO_ACTION: 'Unverändert',
    [ScaffoldState.DRAFT]: 'Vorerfasst',
    [ScaffoldState.CLIENT_REVIEW]: 'Internes Review',
    [ScaffoldState.CLIENT_TRANSMITTED]: 'Übermittelt',
    gdk: 'Bestätigen und an Yncoris übermitteln',
    signed: 'Bereits bestätigt',
    submitTo: 'Übermitteln an {{name}}',
    signAndSubmitTo: 'Bestätigen und übermitteln an {{name}}',
    sign: 'Bestätigen',
    createdOn: 'Auftrag erstellt',
    // createConstructionRequest: 'Gerüstantrag vervollständigen',
    scaffolder: 'Gerüstbauer',
    scaffolderId: 'Gerüstbauer',
    csOrderId: 'SAP-CS-Auftrag',
    scaffoldLength: 'Gerüstlänge',
    scaffoldHeight: 'Gerüsthöhe',
    scaffoldDepth: 'Gerüsttiefe',
    stageCount: 'Anzahl Bühnen',
    footPointHeight: 'Fußpunkthöhe',
    loadClass: 'Lastklasse',
    singleLoadTonnes: 'Einzellast',
    widthLoad: 'Breitenklasse',
    constructionDate: 'Aufbaudatum',
    deconstructionDate: 'Abbaudatum',
    deconstructionDateSetOn: 'Abbaudatum gesetzt am',
    deconstructionDateSetBy: 'Abbaudatum gesetzt von',
    canceledToDate: 'Abmeldedatum',
    canceledToDateSetOn: 'Abmeldedatum gesetzt am',
    canceledBy: 'Abmeldedatum gesetzt von',
    createConstructionRequest: 'Aufmaß erstellen',
    openConstructionRequest: 'Aufmaß aufrufen',
    agentPhase: 'Status',
    [ScaffoldAgentPhase.SCAFFOLDER]: 'Gerüstbauer',
    [ScaffoldAgentPhase.CLIENT]: 'Kunde',
    [ScaffoldAgentPhase.GDK]: 'Yncoris',
    [ScaffoldAgentPhase.FULLY_SIGNED]: 'Vollständig',
    construction: 'Aufmaß Gerüst-Nr. {{item.serialNumber.fullSerial}}',
    csOrder: 'SAP-CS-Auftrag: {{item.csOrderId}}',
    attachments: 'Anhang',
    cancellation: 'Abbauen',
    clone: 'Kopieren',
    convert: 'Umbauen',
    // image: 'Bilder',
    image: 'Bild markieren',
    cancel: 'Abmelden',
    construct: 'Aufbauen',
    deconstruct: 'Abbauen',
    cancelHint: 'Nur Gerüste ohne Abmeldedatum werden geändert',
    deconstructHint: 'Nur Gerüste ohne Abbaudatum werden geändert',
    constructHint: 'Nur Gerüste ohne Aufbaudatum werden geändert',
    rentFreeInDays: 'Mietfrei bis',
    rentFreeInDaysUnit: 'Tag(e)',
    createCertificate: 'Gerüstschein herunterladen',
    withAttachments: 'mit Anhängen',
    withoutAttachments: 'ohne Anhänge',
    cloneOverview: 'Kopierte Gerüste',
    convertOverview: 'Kopierte Gerüste für Umbau',
    createRequests: 'Neuanträge erstellen',
    draftToRequestOverview: 'erstellte Neuanträge',
    noDraftPublished: 'Kein Gerüst konnte beantragt werden.',
    noConvertSucceededHint: 'Keine passenden Gerüste für den Umbau gefunden',
    convertOverviewHint: 'Hinweis: Nur Gerüste ohne Abbaudatum können umgebaut werden.',
    oldSerial: 'Vorher',
    newSerial: 'Neu',
  },
  boolean: {
    true: 'Ja',
    false: 'Nein',
  },
  scaffoldType: {
    New: 'Neubau',
    Change: 'Umbau',
    Conversion: 'Umbau',
  },
  imageEditor: {
    editor: 'Vorschau / Editor',
  },
  roles: {
    SCAFFOLDER: 'Gerüstbauer',
    GDK: 'Yncoris / GDK',
    CUSTOMER: 'Kunde',
    SYSADMIN: 'Administrator',
  },
  scaffoldWorkflow: {
    scaffoldWorkflow: 'Genehmigungsworkflow',
    title: 'Beschreibung Genehmigungsworkflow',
    info: 'Status-Informationen',
    description: 'Der Status nach Abschluss bestimmt welche Felder Pflicht werden. Von der Reihenfolge muss es immer Vorerfasst > Internes Review > Übermittelt an Yncoris sein, eine andere Reihenfolge ist nicht valide.',
    [ScaffoldState.DRAFT]: 'Vorerfasst',
    [ScaffoldState.CLIENT_REVIEW]: 'Internes Review',
    [ScaffoldState.CLIENT_TRANSMITTED]: 'Übermittelt an Yncoris',
    [ScaffoldState.GDK_DRAFT]: 'Zwischengespeichert bei Yncoris',
    [ScaffoldState.GDK_TRANSMITTED]: 'Übermittelt an Gerüstbauer',
    draftDescription: 'Beim übermitteln: Workflow änderbar, Bestell-Nr + Arbeitserlaubnisschein keine Pflichtfelder',
    clientReviewDescription: 'Beim übermitteln: Workflow nicht mehr änderbar, Arbeitserlaubnisschein ist in diesem Prozessschritt nun Pflichtfeld',
    clientTransmitted: 'Beim übermitteln: Bestell-Nr ist in diesem Schritt nun Pflichtfeld',
  },
  customers: {
    name: 'Name',
    emailDomains: 'Email-Domain',
    scaffoldWorkflow: 'Genehmigungsworkflow',
    workflowType: 'Workflowtyp',
    parallel: 'Parallel',
    phased: 'Nacheinander',
    sortPosition: 'Sort.-Nr',
    stepName: 'Name',
    action: 'Aktion',
    type: 'Typ',
    required: 'Erforderlich',
    individualScaffoldOrderFields: 'Kundenspezifische Bestellfelder',
    new: 'Neu',
    steps: 'Schritte',
    notify: 'Benachrichtigen',
    approve: 'Genehmigen',
    text: 'Freitext',
    number: 'Ganze Zahl (z.B. 4)',
    float: 'Gleitkomma Zahl (z.B. 4,5)',
    customers: 'Kunden',
    statusOnCompletion: 'Status nach Abschluss',
    siteMeasuringTemplateId: 'Aufmaß-Template',
    noaction: 'Unverändert',
    always: 'Immer',
    never: 'Nie',
    draft: 'Zwischenspeichern',
    clientReview: 'Kunden-Review',
    gdkReview: 'Yncoris-Prüfung',
    scaffolderAccepted: 'Gerüstbauer-Akzeptiert',
    requested: 'Abgeschickt',
    NEVER: 'Nein',
    ALWAYS: 'Immer',
    NO_ACTION: 'Unverändert',
    [ScaffoldState.DRAFT]: 'Vorerfasst',
    [ScaffoldState.CLIENT_REVIEW]: 'Internes Review',
    [ScaffoldState.CLIENT_TRANSMITTED]: 'Übermittelt an Yncoris',
    [ScaffoldState.GDK_DRAFT]: 'Zwischengespeichert bei Yncoris',
    [ScaffoldState.GDK_TRANSMITTED]: 'Übermittelt an Gerüstbauer',

  },
  users: {
    users: 'Benutzer',
    firstName: 'Vorname(n)',
    lastName: 'Nachname',
    email: 'E-Mail',
    password: 'Passwort',
    roles: 'Berechtigungs-Rolle',
    companyId: 'Firma',
    customerIds: 'im Auftrag auch tätig für',
    tags: 'Tags',
    phoneNumber: 'Telefonnummer',
    status: 'Status',
  },
  userStatus: {
    [UserStatus.Active]: 'Aktiv',
    [UserStatus.Inactive]: 'Deaktiviert',
    [UserStatus.PendingApproval]: 'Wartet auf Genehmigung',
    [UserStatus.PendingEmailVerificationBeforeApproval]: 'Wartet auf E-Mail Verifikation',
  },
  scaffolders: {
    name: 'Name',
  },
  projects: {
    name: 'Name',
    defaultOrderNr: 'Standard-Bestellnr',
  },
  buildings: {
    name: 'Name',
    street1: 'Adresszeile 1',
    street2: 'Adresszeile 2',
    zip: 'PLZ',
    city: 'Stadt',
    country: 'Land',
    description: 'Beschreibung',
    routeDescription: 'Anfahrtsbeschreibung',

  },
  loadClass: {
    2: 'Lastklasse 2 (150 Kg/m²)',
    3: 'Lastklasse 3 (200 Kg/m²)',
    4: 'Lastklasse 4 (300 Kg/m²)',
    5: 'Lastklasse 5 (450 Kg/m²)',
    6: 'Lastklasse 6 (600 Kg/m²)',
  },
  table: {
    excelExport: 'Export nach Excel',
    filterText: 'Aktive Filter: ',
  },
  siteMeasuringComment: {
    save: 'Notiz speichern',
    gdkNote: 'Notiz (Yncoris)',
  },
  dashboard: {
    scaffoldsInRent: 'Gerüste in Miete',
    countOfOpenScaffolds: 'Neue Gerüste',
    countOfCancelledButNotDeconstructedScaffolds: 'zum Abbauen',
    countOfCancelledScaffolds: 'Abgemeldet',
    totalCountOfScaffolds: 'Anzahl Gerüste gesamt',
    countOfScaffoldsWithRent: 'Gerüste in Miete',
    countOfScaffolds: 'Gerüste in Betrieb',
    countOfNoSiteMeasurings: 'ohne Aufmaß',
    countOfNotAccounted: 'zum Abrechnen',
    countOfNotCheckedSiteMeasuring: 'Aufmaße zu prüfen',
    countOfNotConstructedScaffolds: 'zum Aufbauen ',
    countOfScaffoldsInPlanning: 'in Planung',
    countOfScaffoldsByCustomer: 'Aktuelle Gerüste nach Kunde',
    countOfScaffoldsByOperationUnit: 'Aktuelle Gerüste nach Betrieb',
    deltaPreferredToConstructionDate: 'Durchschnittsdelta Wunschaufbau zu tatsächlichem Aufbau',
    deltaCanceledToDateToConstructionDate: 'Durchschnittsdelta Abmeldedatum zu tatsächlichem Abbau',
  },
}
