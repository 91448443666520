'use client'

import { Button, Stack } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { ManagedForm, useManagedForm, useOpenState, ValidatedForm } from '@codeflix/mui-managed-form'
import { styled } from '@mui/system'
import { useCallback, MouseEvent } from 'react'
import Dialog2 from '../../../../framework/components/Dialog2'
import { CustomerModel } from '../../../../api/models/customerModel'
import { useAppDispatch } from '../../../../app/hooks'

import { cloneCustomer } from '../../../../features/cloneCustomer'

const StyledStack = styled(Stack)(({ theme }) => ({
  minWidth: 400,
  padding: theme.spacing(2),
}))

interface IDuplicatePopupProps {
  customer: CustomerModel
}
const handleEvent = (e:MouseEvent) => {
  console.log('handleEvent', e)
  e.stopPropagation()
  e.preventDefault()
}
export default function DuplicatePopup({ customer }: IDuplicatePopupProps) {
  const { t } = useTranslation('customers')
  const { t: tGeneric } = useTranslation('generic')
  const appDispatch = useAppDispatch()
  const { values, formManagerProps } = useManagedForm({ name: customer.name })
  const { open, handleOpen, handleClose } = useOpenState()
  const handleOpen2 = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    handleOpen()
  }, [handleOpen])
  const handleClose2 = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    handleClose()
  }, [handleClose])
  const { name } = values
  const handleSubmit = useCallback(async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    await appDispatch(cloneCustomer(customer.id, name))
    handleClose()
  }, [appDispatch, customer.id, handleClose, name])
  return (
    <>
      <Dialog2 t={t} open={open} onClose={handleClose} title={tGeneric('clone')} onClick={handleEvent}>
        <StyledStack>
          <ValidatedForm
            {...formManagerProps}
            elements={[
              {
                type: 'text',
                name: 'name',
              },
              {
                type: 'submit',
                name: 'clone',
                label: tGeneric('clone'),
                variant: 'contained',
                onClick: handleSubmit,
              },
              {
                type: 'button',
                label: tGeneric('abort'),
                name: 'abort',
                onClick: handleClose2,
              },
            ]}
            t={t}
          />
        </StyledStack>

      </Dialog2>
      <Button aria-label={t('clone')} variant="contained" color="primary" onClick={handleOpen2}><ContentCopy /></Button>
    </>
  )
}
