import { useTheme } from '@mui/material'
import useResizeObserver from '@react-hook/resize-observer'
import { useSize } from 'lib/resizeObserver'
import { Ref } from 'react'

export const useHeight = (divisor: number = 2, reduceBy: number = 0) => {
  const minHeight = 200
  const reduceHeightBy = 360 + reduceBy
  // eslint-disable-next-line no-restricted-globals
  const height = Math.floor((screen.availHeight - reduceHeightBy) / divisor)
  const resultHeight = height > minHeight ? height : minHeight
  return resultHeight
}
