import { RestModel } from 'app/RestModel'
import PermissionModel from 'models/PermissionModel'
import { api } from 'app/apiClient'
import { ScaffoldInquiryModel } from 'api/models/scaffoldModel'
import { CustomerModel } from './models/customerModel'
import { BuildingModel } from './models/buildingModel'
import { OperationUnitModel } from './models/operationUnitModel'

class CustomersRestModel extends RestModel<CustomerModel> {
  path = 'customer'

  getItems = async (parameters: any, permissions: string[]|undefined) => {
    const fetchAllUsers = permissions?.includes('VIEW_ALL_CUSTOMERS')
    if (fetchAllUsers) return super.getItems(parameters, permissions, fetchAllUsers)

    if (permissions?.includes(PermissionModel.VIEW_CUSTOMERS_WITH_SCAFFOLD_FOR_MY_COMPANY)) {
      // scaffolder
      return await api.get(`${this.path}/MyScaffoldCustomers`).json() as CustomerModel[]
    }

    return super.getItems(parameters, permissions, fetchAllUsers)
  }

  cloneCustomer = async (sourceCustomerId: string, newCustomerName: string) => await api.post(`${this.path}/${sourceCustomerId}/clone`, {
      json: newCustomerName,
    }).json() as {
    customer: CustomerModel
    buildings: BuildingModel[],
    operationUnits: OperationUnitModel[],
  }
}

export default new CustomersRestModel()
