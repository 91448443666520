import {IProps} from 'ui/views/ScaffoldInquiries/EditActions/IProps'
import {useAppDispatch} from 'app/hooks'
import {useTranslation} from 'react-i18next'
import {useOpenState} from 'lib/stateHelper'
import {useNavigate} from 'react-router-dom'
import {useCallback, useMemo} from 'react'
import {cancelScaffolds, constructScaffolds, deconstructScaffolds} from 'features/scaffoldInquiries'
import {Typography} from '@mui/material'
import {PopupActionMenuItem} from 'framework/components/PopupActions/PopupActionMenuItem'
import {ManagedForm} from '@codeflix/mui-managed-form'
import {useUserHasPermissions} from 'framework/auth/permissions'
import {PermissionOperation} from 'framework/auth/permissionOperation'
import PermissionModel from 'models/PermissionModel'

interface ICancelActionProps extends IProps {
  disabled?: boolean,
  mode: 'cancel' | 'deconstruct'|'construct'
}

export function CancelAction({ mode, disabled, item, t }: ICancelActionProps) {
  const appDispatch = useAppDispatch()

  const { t: genericT } = useTranslation('generic')
  const openState = useOpenState()
  const navigate = useNavigate()
  const allowEdit = useUserHasPermissions({
    operation: PermissionOperation.AND,
    permissions: [PermissionModel.EDIT_ALL_CUSTOMERS, PermissionModel.EDIT_ALL_SCAFFOLD_REQUESTS_MY_CUSTOMER],
  })
  const allowPastDateCancelAction = useUserHasPermissions({
    permissions: [PermissionModel.SCAFFOLD_ALLOW_CANCELED_TO_DATE_IN_PAST],
    operation: PermissionOperation.AND,
  })
  const blockPastDateInCalendar = mode === 'cancel' && !allowPastDateCancelAction
  console.log('blockdate in past', mode, allowPastDateCancelAction)
  const { open, handleOpen, toggle, handleClose } = openState
  const handleCancel = useCallback(async (values: any) => {
    const original = [item]
    switch (mode) {
      case 'cancel':
        if (await appDispatch(cancelScaffolds(original, values.date))) navigate('..')
        break
      case 'construct':
        if (await appDispatch(constructScaffolds(original, values.date))) navigate('..')
        break
      case 'deconstruct':
        if (await appDispatch(deconstructScaffolds(original, values.date))) navigate('..')
        break
      default:
        break
    }
    handleClose()
  }, [appDispatch, handleClose, item, mode])

  const dateString = useMemo(() => {
    switch (mode) {
      case 'cancel':
        return t('canceledToDate')
      case 'deconstruct':
        return t('deconstructionDate')
      case 'construct':
        return t('constructionDate')
      default:
        return ''
    }
  }, [mode, t])

  const initial = useMemo(() => ({ date: new Date().toISOString() }), [])
  return (
    <PopupActionMenuItem
      t={t}
      label={t(mode)}
      disabled={disabled}
      openState={openState}
    >
      <Typography variant="body1">{t(mode === 'cancel' ? 'cancelHint' : 'deconstructHint')}</Typography>
      <ManagedForm
        initial={initial}
        elements={[
          {
            type: 'calendar',
            name: 'date',
            label: dateString,
            disablePast: blockPastDateInCalendar,
          },
          {
            type: 'button',
            variant: 'contained',
            onClick: handleClose,
            name: 'abort',
            label: genericT('abort'),
            grid: { xs: 12, sm: 6 },
          },
          {
            type: 'submit',
            name: 'save',
            label: genericT('save'),
            color: 'success',
            grid: { xs: 12, sm: 6 },

          },
        ]}
        onSubmit={handleCancel}
        t={t}
      />
    </PopupActionMenuItem>
  )
}
