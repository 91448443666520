/* eslint-disable */
import {RootState} from 'app/store'
import BuildingsRestModel from "api/buildings";
import LoadStateModel from "../framework/LoadStateModel";
import {useSelector} from "react-redux";
import useNamedOptions from "../lib/useNamedOptions";
import {BuildingModel} from "../api/models/buildingModel";
import {IBaseRestState} from "features/base/BaseRestFeature";
import {createNamedCustomerBaseRestSlice} from "features/base/creators/CreateNamedCustomerBaseRestSlice";
import { INamedBaseModel } from 'api/baseModel'

type DataType = BuildingModel
const key:string = "buildings"

const initialState: IBaseRestState<DataType> = {
  data: [],
  loadState: LoadStateModel.INITIAL,
  archive: {},
}
export const { actions, index, hooks } = createNamedCustomerBaseRestSlice(
  key,
  initialState,
  {},
  BuildingsRestModel,
)
const { get, update, deleteItem } = actions
export default index.reducer

export const updateBuilding= update
export const deleteBuilding = deleteItem
export const fetchData = get
export const useBuildingsById = hooks.useById
export const useBuildings = hooks.useAll
export const useBuildingsByCustomerId = hooks.useByCustomerId
export const useBuildingsByCustomerIdOptions = hooks.useNamedOptionsByCustomerId

export const useBuildingByPath = hooks.useByPathKey
export const useBuildingsByCustomerIdAndBuildingIds = (id: string,buildingIds:string[]) => {
  return useSelector((state:RootState)=>state.buildings.data.filter(x=>x.customerId === id && buildingIds.includes(x.id)))
}
export const buildingsMapFctNamedOptions = (x:BuildingModel) => ({
  label: `${x.name} (${x.description})`,
  value: x.id,
})
export const useBuildingOptions = (customerId: string, buildingIds: string[],includeArchived: boolean|undefined) => {
  const data = useBuildingsByCustomerIdAndBuildingIds(customerId,buildingIds)
  return useNamedOptions<BuildingModel>(data || [],includeArchived,(x)=>({
    label: `${x.name} (${x.description})`,
    value: x.id,
  }))
}
