import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatDate } from 'framework/components/FormattedDate'
import { useCustomers } from 'features/customers'
import { useUserHasAllPermissions } from 'framework/auth/permissions'
import PermissionModel from 'models/PermissionModel'
import {
  IScaffoldInquiryModelAggregated, ScaffoldAgentPhase,
  ScaffoldInquiryModel,
  ScaffoldInquiryServerModel,
} from 'api/models/scaffoldModel'
import { DateCellProps } from 'framework/components/Table/Cells/DateTimeCell'
import { RemainingDaysCell } from 'framework/components/Table/Cells/RemainingDaysCell'
import { useBuildings } from 'features/buildings'
import { useProjects } from 'features/projects'
import { ProjectModel } from 'api/models/projectModel'
import { BuildingModel } from 'api/models/buildingModel'
import { useOperationUnits } from 'features/operationUnits'
import { OperationUnitModel } from 'api/models/operationUnitModel'
import { useUsers } from 'features/users'
import { UserModel } from 'api/models/UserModel'
// eslint-disable-next-line camelcase
import { LookupAccessorFn } from 'framework/components/Table/helper/lookupAccessorFn'
import { CheckboxCellProps } from 'framework/components/Table/Cells/CheckboxCell'
import { ColumnDef, Row } from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { useIsOnlyCustomer } from 'ui/userRoles/useIsOnlyCustomer'
import { addDays } from 'lib/addDays'
import { numberToString } from '@codeflix/mui-managed-form'
import { CustomerModel } from 'api/models/customerModel'
import { persistedUserToString } from '../../../api/models/persistedUserToString'

const useCustomerColumns = (data:ScaffoldInquiryModel[], t:TFunction) => {
  // const myCompanyType = useMyCompanyType()
  const isOnlyCustomer = useIsOnlyCustomer()
  // const
  return useMemo(() => {
    if (!isOnlyCustomer) {
      return [

        {
          header: t('customers:individualScaffoldOrderFields'),
          id: 'individualScaffoldOrderFields',
          accessorFn: (row: any) => (row as ScaffoldInquiryModel).customerIndividualFields?.reduce((acc, cur, index) => `${acc}${index > 0 ? ', ' : ''}${cur.name}: ${row.individualFields[cur.id] || ''}`, ''),
          filterFn: 'includesString',
        },
      ]
    }
    const columns = new Map()
    const columns2 :{[key: string]: string[]} = {}
    data?.forEach((row) => {
      row.customerIndividualFields?.forEach((field) => {
        if (columns2[field.name]) {
          columns2[field.name].push(field.id)
        } else {
          columns2[field.name] = [field.id]
        }
        columns.set(`${field.id}_${field.name}`, field)
      })
    })
    // const mapColumns = ([...columns.values()] as CustomerSpecificField[]).map((field) => ({
    //   header: field.name,
    //   id: `${field.id}${field.name}`,
    //   accessorFn: (row:ScaffoldInquiryModel) => {
    //     if (row.customerIndividualFields?.some((x) => x.id === field.id && x.name === field.name)) return row.individualFields[field.id]
    //     return ''
    //   },
    // }) as ColumnDef<ScaffoldInquiryModel>)
    const mapColumns = Object.entries(columns2).map(([key, ids]) => ({
      header: key,
      id: `individualField${key}`,
      accessorFn: (row:ScaffoldInquiryModel) => {
        const field = row.customerIndividualFields?.find((x) => ids.includes(x.id) && x.name === key)
        if (field) return row.individualFields[field.id]
        return ''
      },
    }) as ColumnDef<ScaffoldInquiryModel>)
    return mapColumns
  }, [data, isOnlyCustomer, t])
}

export const useOverviewData = (data:ScaffoldInquiryModel[]) => {
  const { t } = useTranslation('scaffolds')
  const formatDate = useFormatDate()
  const customers = useCustomers()
  const buildings = useBuildings()
  const operationUnits = useOperationUnits()
  const projects = useProjects()
  const users = useUsers()
  const customerIndividualColumns = useCustomerColumns(data, t)
  const gdkPermission = useUserHasAllPermissions([PermissionModel.TRANSMIT_SCAFFOLD_REQUEST_TO_SCAFFOLDER])
  const scaffolderPermission = useUserHasAllPermissions([PermissionModel.CREATE_SITE_MEASURING_FOR_SCAFFOLD_REQUEST])
  const downloadCertificatePermission = useUserHasAllPermissions([PermissionModel.GET_SCAFFOLD_CERTIFICATE])
  // eslint-disable-next-line camelcase
  const columns = useMemo(() => [
    {
      header: t('generic:id'),
      accessorKey: 'id',
      id: 'id',
      filterFn: 'includesString',
    },
    {
      id: 'createdOn',
      header: t('createdOn'),
      ...DateCellProps('createdOn'),
      // datetimeFormat: 'DATETIMEWITHDAY',
      // filterFn: 'includesString',
    },
    {
      header: t('serialNr'),
      accessorKey: 'serialNumber.fullSerial',
      id: 'serialNr',
      filterFn: 'includesString',
    },
    {
      header: t('constructionDate'),
      id: 'constructionDate',
      ...DateCellProps('constructionDate'),
    },
    {
      header: t('canceledToDate'),
      id: 'canceledToDate',
      ...DateCellProps('canceledToDate'),
    },
    {
      header: t('canceledToDateSetOn'),
      id: 'canceledToDateSetOn',
      ...DateCellProps('canceledToDateSetOn'),
    },
    {
      header: t('canceledBy'),
      id: 'canceledBy',
      accessorFn: (row:IScaffoldInquiryModelAggregated) => persistedUserToString(row.canceledBy),
    },
    {
      header: t('rentFreeInDays'),
      id: 'rentFreeInDays',
      accessorFn: (row:any) => addDays(row.constructionDate, row.canceledToDate || row.deconstructionDate, row.rentFreeInDays),
      exportAccessorFn: (value:any) => (typeof value.days === 'number' ? `${numberToString(value.days)} ${t('units:dayS')}` : undefined),
      Cell: RemainingDaysCell,
      getColor: (value: any) => {
        if (value < 0) return 'error'
        if (value <= 7) return 'warning'
        return undefined
      },
      sortingFn: (aRow:Row<ScaffoldInquiryModel>, bRow: Row<ScaffoldInquiryModel>, columnId:string) => {
        const a = (aRow.getValue(columnId) as any)?.days || 0
        const b = (bRow.getValue(columnId) as any)?.days || 0
        // eslint-disable-next-line no-nested-ternary
        return a > b ? 1 : a < b ? -1 : 0
      },
      enableColumnFilter: false,
      enableSorting: true,
      // filterVariant: 'range',
      // filterFn: 'betweenInclusive',
      // filterFn: 'includesString',
    },
    {
      header: t('adHocOrder'),
      accessorKey: 'adHocOrder',
      id: 'adHocOrder',
      ...CheckboxCellProps(),
    },
    // {
    //   header: t('rentFreeInDays2'),
    //   accessorKey: 'rentFreeInDays',
    //   // accessorFn: (row:any) => `${addDays(row.constructionDate, row.rentFreeInDays)}`,
    //   // Cell: ({ cell }:any) => `${cell.getValue()} ${t('generic:days')}`,
    //   // filterVariant: 'range',
    //   // filterFn: 'betweenInclusive',
    //   // filterFn: 'includesString',
    // },
    {
      header: t('deconstructionDate'),
      id: 'deconstructionDate',
      ...DateCellProps('deconstructionDate'),
    },
    {
      header: t('deconstructionDateSetOn'),
      id: 'deconstructionDateSetOn',
      ...DateCellProps('deconstructionDateSetOn'),
    },
    {
      header: t('deconstructionDateSetBy'),
      id: 'deconstructionDateSetBy',
      accessorFn: (row:IScaffoldInquiryModelAggregated) => persistedUserToString(row.deconstructionDateSetBy),
    },
    {
      id: 'preferredConstructionDate',
      header: t('preferredConstructionDate'),
      ...DateCellProps('preferredConstructionDate'),
    },
    {
      id: 'preferredDeconstructionDate',
      header: t('preferredDeconstructionDate'),
      ...DateCellProps('preferredDeconstructionDate'),
    },
    {
      header: t('customerId'),
      accessorFn: (row: any) => (row as IScaffoldInquiryModelAggregated)?.customer?.name || customers.find((x:CustomerModel) => x.id === row.customerId)?.name || '',
      filterFn: 'includesString',
    },
    {
      id: 'agentPhase',
      header: t('agentPhase'),
      accessorFn: (row:ScaffoldInquiryModel) => {
        const converted = (row as ScaffoldInquiryServerModel)
        if (row.agentPhase === ScaffoldAgentPhase.CLIENT && converted.currentWorkflowStep) {
          return `${t(row.agentPhase)} (${converted?.currentWorkflowStep.name})`
        }
        return t(row.agentPhase).toString()
      },
      filterFn: 'includesString',
    },
    {
      header: t('operationUnitId'),
      accessorFn: LookupAccessorFn<OperationUnitModel>(operationUnits, ['operationUnit'], 'operationUnit', undefined, 'name'),
      filterFn: 'includesString',
    },
    {
      id: 'buildingId',
      header: t('buildingId'),
      accessorFn: LookupAccessorFn<BuildingModel>(buildings, ['building'], 'building', undefined, 'name'),
      filterFn: 'includesString',
    },
    {
      header: t('projectId'),
      id: 'projectId',
      accessorFn: LookupAccessorFn<ProjectModel>(projects, ['project'], 'project', undefined, 'name'),
      filterFn: 'includesString',
    },
    {
      header: t('area'),
      accessorKey: 'area',
      filterFn: 'includesString',
    },
    {
      header: t('orderNr'),
      accessorKey: 'orderNr',
      filterFn: 'includesString',
    },
    {
      header: t('type'),
      id: 'type',
      accessorFn: (row:any) => t(`scaffoldType:${row.type}`),
      filterFn: 'includesString',
    },

    {
      id: 'orderedByUserId',
      header: t('orderedByUserId'),
      accessorFn: (row:any) => {
        const value = LookupAccessorFn<UserModel>(users, ['orderedByUser'], 'orderedBy', undefined, undefined)(row) as UserModel
        if (!value) return ''
        return `${value.firstName} ${value.lastName}`
      },
      filterFn: 'includesString',
    },
    ...customerIndividualColumns,

  ], [t, formatDate])
  return { columns, gdkPermission, scaffolderPermission, downloadCertificatePermission }
}
