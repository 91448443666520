import { Draft, PayloadAction, Slice } from '@reduxjs/toolkit'
import LoadStateModel from 'framework/LoadStateModel'
import { addToState, editById, removeFromState, setDataToNestedKey } from 'framework/lib/reducerHelper'
import { IBaseModel, IUpdateResult } from 'api/baseModel'
import { RestModel } from 'app/RestModel'
import { capitalizeFirstLetter } from 'lib/capitalize'
import { AppThunk, store } from 'app/store'
import i18n from 'i18next'
import { displayNotification } from 'features/notifications'

export function BaseModelReducer<State, T extends IBaseModel>(initialState: State) {
  return {
    setArchive: (state: Draft<State>, action: PayloadAction<{year: string, data: T[]}>) => {
      if (action) {
        return setDataToNestedKey(state, 'archive', action.payload.year, action.payload.data)
      }
      return state
    },
    setData: (state: Draft<State>, action: PayloadAction<T[]>) => {
      if (action) {
        return {
          ...state,
          data: action.payload,
          loadState: LoadStateModel.LOADED,
        }
      }
      return { ...initialState }
    },
    create: (state: Draft<State>, action: PayloadAction<T>) => addToState(state, 'data', action.payload),
    update: (state: Draft<State>, action: PayloadAction<T>) => editById(state, 'data', action.payload),
    delete: (state: Draft<State>, action: PayloadAction<T>) => removeFromState(state, 'data', action.payload),
    reset: (state:Draft<State>, action: PayloadAction<unknown>) => ({ ...initialState }),
  }
}
type Key<T> = Extract<keyof T, string>

export interface IBaseRestState<T extends IBaseModel> {
  data: T[],
  loadState: LoadStateModel
  archive: { [key:string]: T[]|undefined}
}

export type BaseRestReducerActionsType = { [key: string]: (...params: any[]) => AppThunk }

export interface IBaseRestReducerActions<T> {
  get: () => AppThunk,
  create: (data: T) => Promise<AppThunk>,
  update: (data: T) => AppThunk,
  archive: (data: T) => AppThunk,
  delete: (data: T) => AppThunk,
  reset: ()=>AppThunk
}

// export function BaseRestReducerActions<T extends IBaseModel>(name: string, restModel: RestModel<T>, { actions }: Slice<IBaseRestState<T>, any, string>): BaseRestReducerActionsType {
export function BaseRestReducerActions<T extends IBaseModel>(name: string, restModel: RestModel<T>, { actions }: Slice<any, any, string>): BaseRestReducerActionsType {
  const capitalized = capitalizeFirstLetter(name)
  const { setData, setArchive, update, create, delete: deleteItem, reset } = actions as any
  return {
    get: (parameters:any): AppThunk => async (dispatch, getState) => {
      try {
        const userPermissions = getState().user.user?.permissions
        const data = await restModel.getItems(parameters, userPermissions) as T[]
        if (data) {
          if (parameters?.archive === true) {
            // archive mode, because year is defined
            const { year } = parameters
            dispatch(setArchive({ year, data }))
          } else dispatch(setData(data))
        }
      } catch (e) {
        // do nothing
      }
    },
    create: (data: T): AppThunk => async (dispatch, getState) => {
      try {
        const response = await restModel.createItem(data) as IBaseModel|string
        if (response) {
          if (typeof response === 'string') {
            dispatch(create({ ...data, response, isNew: false }))
          } else {
            dispatch(create({ ...response }))
          }
          // const id = typeof response === 'string' ? response : response?.id
          // dispatch(create({ ...data, id, isNew: false }))
          const message = `${i18n.t('api:saveSuccess')}`
          const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
          store.dispatch(snackbar)
          return response
        }
      } catch (e) {
        //
      }
      return undefined
    },
    update: (data: T): AppThunk => async (dispatch, getState) => {
      try {
        const response = await restModel.updateItem(data) as IBaseModel | IUpdateResult
        if (response) {
          dispatch(update((response as IUpdateResult)?.isAcknowledged ? data : response as IBaseModel))
          const message = `${i18n.t('api:saveSuccess')}`
          const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
          store.dispatch(snackbar)
          return true
        }
        return false
      } catch (e) {
        //
        return false
      }
    },
    archive: (data: T): AppThunk => async (dispatch, getState) => {
      try {
        const response = await restModel.archiveItem(data)
        if (response) {
          dispatch(update({ ...data, archived: true }))
          const message = `${i18n.t('api:archiveSuccess')}`
          const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
          store.dispatch(snackbar)
          return true
        }
        return false
      } catch (e) {
        //
        return false
      }
    },
    delete: (data: T): AppThunk => async (dispatch, getState) => {
      try {
        const response = await restModel.deleteItem(data.id)
        if ([200, 204].includes(response.status)) {
          dispatch(deleteItem(data))
          return true
        }
        return false
      } catch (e) {
        //
        return false
      }
    },
    reset: ():AppThunk => (dispatch) => {
      dispatch(reset())
    },
  }
}

export interface BaseRestSliceType<T extends IBaseModel> {
  index: Slice<IBaseRestState<T>, any, string>,
  actions: BaseRestReducerActionsType,
  selectors: any,
  hooks: any
}

export type BaseRestActionType<T extends IBaseModel> = Slice<IBaseRestState<T>, any, string>

// export const byCustomerId = (key: string) => (id: string) => (state: RootState) => (state[key] as any).data.filter((x) => x.customerId === id)
