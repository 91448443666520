import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import OverviewView from 'framework/components/BaseRestViews/OverviewView'
import { useCustomerByPath } from 'features/customers'
import { useParams } from 'react-router'
import { useBuildingsByCustomerId } from '../../../../../features/buildings'
import { CheckboxCellProps } from '../../../../../framework/components/Table/Cells/CheckboxCell'
import { BuildingModel } from '../../../../../api/models/buildingModel'

export default function Overview() {
  const { customerId } = useParams<{customerId: string}>()
  const customer = useCustomerByPath()
  const data = useBuildingsByCustomerId(customerId || '')
  const { t } = useTranslation('buildings')
  const columns = useMemo(() => [
    {
      header: t('general:id'),
      accessorKey: 'id',
      id: 'id',
      enableHiding: true,
      filterFn: 'includesString',
    },
    {
      header: t('name'),
      accessorKey: 'name',
      filterFn: 'includesString',
    },
    {
      header: t('description'),
      accessorKey: 'description',
      filterFn: 'includesString',
    },
    {
      header: t('routeDescription'),
      accessorKey: 'routeDescription',
      filterFn: 'includesString',
    },
    {
      header: t('street1'),
      id: 'street1',
      accessorKey: 'address.street1',
      filterFn: 'includesString',
    },
    {
      header: t('street2'),
      id: 'street2',
      accessorKey: 'address.street2',
      filterFn: 'includesString',
    },
    {
      header: t('zip'),
      id: 'zip',
      accessorKey: 'address.zip',
      filterFn: 'includesString',
    },
    {
      header: t('city'),
      id: 'city',
      accessorKey: 'address.city',
      filterFn: 'includesString',
    },
    {
      header: t('country'),
      id: 'country',
      accessorKey: 'address.country',
      filterFn: 'includesString',
    },
    {
      header: t('generic:archived').toString(),
      accessorKey: 'archived',
      // accessorFn: (row:any) => row?.archived === true,
      ...CheckboxCellProps(),
    },
  ], [t])
  return (
    // eslint-disable-next-line no-undef
    <OverviewView<BuildingModel>
      data={data || []}
      columns={columns as any[]}
      initialState={{
        showColumnFilters: false,
        columnVisibility: {
          id: false,
          routeDescription: false,
          street1: false,
          street2: false,
          city: false,
          country: false,
        },
      }}
      name={t('application:buildings').toString()}
      linkStack={[
        { to: '/administration/customers', text: t('application:customers') },
        { to: `/administration/customers/${customer?.id}`, text: `${customer?.name}` },
        { to: `/administration/customers/${customer?.id}/buildings`, text: t('application:buildings') },
      ]}
    />
  )
}
