import { api } from 'app/apiClient'
import { Options } from 'ky'
import { IBaseModel } from '../api/baseModel'
// type T<T2> = <T2 extends IBaseModel></
// type RestModelType<T extends IBaseModel> = T & {isNew?: boolean};

const clearIdFromPayload = (value:any) => ({
  ...value,
  id: undefined,
})
export class RestModel<T extends IBaseModel & {isNew?: boolean}> {
  path = ''

  async getItems(parameters: any, permissions?: string[], fetchAll:boolean = false, query: string = ''):Promise<T[]> {
    if (!fetchAll) {
      return api.get(`${this.path}/mycustomer${query}`).json()
    }
    return api.get(`${this.path}${query}`).json()
  }

  async createItem(payload: T, options?: Partial<Options>):Promise<unknown> {
    return api.post(this.path, {
      json: clearIdFromPayload(payload),
      ...options,
    }).json()
  }

  async updateItem(payload: T, options?: Partial<Options>):Promise<unknown> {
    return api.put(this.path, { json: payload, ...options }).json()
  }

  async archiveItem(payload: T) {
    return api.put(`${this.path}/${payload.id}/archive`).json()
  }

  async deleteItem(id: string) {
    return api.delete(`${this.path}/${id}`)
  }

  createOrUpdateItem(payload: T, options?: Partial<Options>) {
    if (payload.isNew) {
      return this.createItem({ ...payload, id: undefined }, options)
    }
    return this.updateItem(payload, options)
  }
}
