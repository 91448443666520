import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useOperationUnitsByPath } from 'features/operationUnits'
import OverviewView from '../../../../../framework/components/BaseRestViews/OverviewView'
import { useCustomerByPath } from '../../../../../features/customers'
import { CheckboxCellProps } from '../../../../../framework/components/Table/Cells/CheckboxCell'

export default function OperationUnits() {
  const data = useOperationUnitsByPath()
  const customer = useCustomerByPath()
  const { t } = useTranslation('customers')
  const columns = useMemo(() => [
    {
      header: t('general:id'),
      accessorKey: 'id',
      enableHiding: true,
      filterFn: 'includesString',
    },
    {
      header: t('name'),
      accessorKey: 'name',
      filterFn: 'includesString',
    },
    {
      header: t('generic:archived').toString(),
      accessorKey: 'archived',
      // accessorFn: (row:any) => row?.archived === true,
      ...CheckboxCellProps(),
    },
  ], [t])
  const linkStack = useMemo(() => [
    { to: '/administration/customers', text: t('application:customers') },
    { to: `/administration/customers/${customer?.id}`, text: `${customer?.name}` },
    { text: t('application:operationUnits') },
  ], [customer])
  return (
    <OverviewView
      name={t('application:operationUnits').toString()}
      data={data || []}
      linkStack={linkStack}
      columns={columns}
    />
  )
}
