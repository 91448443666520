import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useCustomers } from 'features/customers'
import { CustomerModel, ICustomerModel } from 'api/models/customerModel'
import { Stack } from '@mui/material'
import ButtonLink from '../../../../framework/components/ButtonLink'
import OverviewView from '../../../../framework/components/BaseRestViews/OverviewView'
import DuplicatePopup from './DuplicatePopup'

export default function Customers() {
  const customers = useCustomers()
  const { t } = useTranslation('customers')
  const columns = useMemo(() => [
    {
      header: t('generic:id'),
      accessorKey: 'id',
      filterFn: 'includesString',
    },
    {
      header: t('name'),
      accessorKey: 'name',
      filterFn: 'includesString',
    },
  ], [t])
  // const onRowClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
  //
  // },[navigate])
  return (
    <OverviewView<CustomerModel>
      linkStack={[
        { text: t('application:customers') },
      ]}
      data={customers || []}
      columns={columns}
      enableRowActions
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: '', // change header text
        },
      }}
      name={t('application:customers').toString()}
      positionActionsColumn="last"
      renderRowActions={({ table, row }) => (
        <Stack direction="row" spacing={1}>
          <ButtonLink to={`/administration/customers/${(row.original as ICustomerModel).id}/buildings`} >{t('application:buildings')}</ButtonLink>
          <ButtonLink to={`/administration/customers/${(row.original as ICustomerModel).id}/operationUnits`} >{t('application:operationUnits')}</ButtonLink>
          <ButtonLink to={`/administration/customers/${(row.original as ICustomerModel).id}/projects`} >{t('application:projects')}</ButtonLink>
          <DuplicatePopup customer={row.original} />
        </Stack>
      )}
    />
  )
}
