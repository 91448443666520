import i18n from 'i18next'
import { AppThunk } from '../app/store'
import CustomersRestModel from '../api/customers'
import { displayNotification } from './notifications'
import { index } from './customers'
import { index as indexBuildings } from './buildings'
import { index as indexOperationUnits } from './operationUnits'

export const cloneCustomer = (sourceCustomerId: string, newName: string): AppThunk => async (dispatch) => {
  try {
    const result = await CustomersRestModel.cloneCustomer(sourceCustomerId, newName)
    await dispatch(index.actions.create(result.customer))
    console.log('request', result)
    for (let i = 0; i < result.buildings.length; i += 1) {
      const building = result.buildings[i]
      // eslint-disable-next-line no-await-in-loop
      await dispatch(indexBuildings.actions.create(building))
    }
    for (let i = 0; i < result.operationUnits.length; i += 1) {
      const operationUnit = result.operationUnits[i]
      // eslint-disable-next-line no-await-in-loop
      await dispatch(indexOperationUnits.actions.create(operationUnit))
    }

    const message = `${i18n.t('api:saveSuccess')}`
    const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
    dispatch(snackbar)
    return true
  } catch (e) {
    return false
  }
}
