import { useCustomerByPath } from 'features/customers'
import {
  ICellAddress,
  IExternalColumn,
  IFormManagerProps,
  PathType,
  SpreadSheet,
  useManagedState,
} from '@codeflix/mui-spreadsheet'
import { useDraftsByCustomerId } from 'features/scaffoldInquiriesDraft'
import { ScaffoldDraftModel } from 'api/models/scaffoldDraftModel'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { resolveIndividualFieldType, useEditorData } from 'ui/views/ScaffoldInquiries/EditorHelper'
import { buildingsMapFctNamedOptions, useBuildingsByCustomerId } from 'features/buildings'
import { OperationUnitModel } from 'api/models/operationUnitModel'
import { BuildingModel } from 'api/models/buildingModel'
import { ScaffoldAgentPhase, ScaffoldInquiryModel, ScaffoldState } from 'api/models/scaffoldModel'
import { ApproverStep, CustomerModel, CustomerSpecificField } from 'api/models/customerModel'
import AttachmentPopup from 'ui/views/ScaffoldInquiries/MassEditor/AttachmentPopup'
import { useCurrentUser } from 'features/user'
import LoadingCircle from 'framework/components/DataLoader/LoadingCircle'
import BreadCrumb from 'framework/components/BreadCrumb'
import { useSynchronization } from 'ui/views/ScaffoldInquiries/MassEditor/useSynchronization'
import { UserModel } from 'api/models/UserModel'
import SelectionTools from 'ui/views/ScaffoldInquiries/MassEditor/SelectionTools/SelectionTools'
import { useUserTableOptions } from 'ui/helper/useUserOptions'
import { Sync } from 'mdi-material-ui'
import styled2 from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { IValidatedFormAutocompleteOptions } from '@codeflix/mui-managed-form'
import { v4 as uuidv4 } from 'uuid'

const clearPayload = (payload: ScaffoldDraftModel) => ({
  ...payload,
  rentFreeInDays: payload.agentPhase === 'CLIENT' ? undefined : payload.rentFreeInDays,
  clientWorkflowSteps: undefined,
  orderedByUser: undefined,
  copiedFromScaffoldInquiryId: undefined,
  scaffoldReferenceSerialNumber: undefined,
  attachmentCount: undefined,
  csOrderId: payload.agentPhase === ScaffoldAgentPhase.GDK || payload.agentPhase === ScaffoldAgentPhase.SCAFFOLDER ? payload.csOrderId : undefined,
})
const rotate = keyframes`
  100% {
      transform: rotate(-360deg);
  }
`
const SyncRotating = styled2(Sync)`
  &[data-syncing=true] {
    animation: ${rotate} 3s linear infinite;
  }
`
function Table({
  user, customer,
               }: {
  customer: CustomerModel,
  user: UserModel
}) {
  const { t, i18n } = useTranslation('scaffolds')

  const data = useDraftsByCustomerId(customer.id)
  const defaultRow = useCallback(() => {
    const model = new ScaffoldInquiryModel(user?.id, customer?.id)
    const data = {
      ...model,
      modelType: 'USER_DRAFT',
      lastModified: new Date().toISOString(),
      isSynchronized: false,
      id: uuidv4(), // window.crypto.randomUUID(),
      draftOwner: user?.id,
      isNew: true,
      attachmentCount: 0,
    }
    return data
  }, [user, customer])
  const state = useManagedState(data, false, undefined, defaultRow)
  const synchronization = useSynchronization(state, customer)

  const buildings = useBuildingsByCustomerId(customer?.id)
  const { operationUnits, projectOptions, operationUnitOptions, buildingOptions } = useEditorData(customer?.id, '', ScaffoldAgentPhase.CLIENT)

  const userOptions = useUserTableOptions(customer?.id, false)
  const primaryUserOptions = useUserTableOptions(customer?.id, true)

  const columns = useMemo(() => [
    {
      type: 'checkbox',
      name: 'selected',
      width: 40,
    },
    {
      type: 'action',
      name: 'lastModified',
      width: 40,
      additionalProps: {
        render: (_cellAddress: ICellAddress, row: ScaffoldDraftModel) => (
          <SyncRotating data-syncing={synchronization[row?.id] && row?.lastModified !== synchronization[row?.id]} />
        ),
      },
    },
    {
      type: 'action',
      name: 'rowNumber',
      width: 40,
      additionalProps: {
        render: (cellAddress: ICellAddress) => cellAddress.row + 1,
      },
    },
    {
      type: 'action',
      name: 'attachments',
      additionalProps: {
        render: (cellAddress: ICellAddress, row:ScaffoldDraftModel, state1:IFormManagerProps<ScaffoldDraftModel>) => (<AttachmentPopup state={state1} cellAddress={cellAddress} value={row} scaffoldInquiryId={row?.id} disabled={!row || row?.isNew} />),
      },
    },
    {
      type: 'autocomplete',
      name: 'operationUnitId',
      required: true,
      // width: 200,
      additionalProps: {
        options: operationUnitOptions,
      },
    },
    {

      type: 'autocomplete',
      name: 'buildingId',
      required: true,
      // width: 200,
      additionalProps: {
        options: (value: any, row: ScaffoldDraftModel) => {
          const operationUnit = operationUnits?.find((x:OperationUnitModel) => x?.id === row?.operationUnitId)
          const buildingsFiltered = buildings?.filter((x:BuildingModel) => operationUnit?.buildings?.includes(x.id))
          const result = (buildingsFiltered?.map(buildingsMapFctNamedOptions) || []) as IValidatedFormAutocompleteOptions[]

          return result?.sort((a, b) => -b.label.localeCompare(a.label))
        },
        minWidth: 250,
      },
    },
    {
      type: 'text',
      name: 'area',
      required: true,
    },
    {
      type: 'autocomplete',
      name: 'projectId',
      required: false,
      additionalProps: {
        options: projectOptions,
        onHandleOptionSelectedWithOptionalUpdates: (option: any, path: PathType[], row: ScaffoldDraftModel) => ({
            path: [path[0], 'orderNr'],
            value: option.defaultOrderNr,
          }),
      },
    },
    {
      type: 'text',
      required: customer?.scaffoldWorkflow?.length === 0,
      name: 'orderNr',
      // required: true,
    },
    {
      type: 'date',
      name: 'preferredConstructionDate',
      required: true,
      additionalProps: {
        format: t('generic:dateFormat'),
        shortFormat: t('generic:shortDateFormat'),
      },
      width: 155,
    },
    {
      type: 'date',
      name: 'preferredDeconstructionDate',
      additionalProps: {
        format: t('generic:dateFormat'),
        shortFormat: t('generic:shortDateFormat'),
      },
      width: 136,
    },
    // { // designed to be not in editor due to meeting
    //   type: 'boolean',
    //   name: 'adHocOrder',
    //   required: true,
    //   width: 157,
    // },
    {
      type: 'autocomplete',
      name: 'permitRequired',
      required: customer?.scaffoldWorkflow?.length === 0,
      additionalProps: {
        options: [{ value: true, label: t('necessary') }, { value: false, label: t('unnecessary') }],
      },
      width: 160,
    },
    // { // always type new, for change other one
    //   type: 'autocomplete',
    //   name: 'type',
    //   additionalProps: {
    //     options: [{ value: 'New', label: t('scaffoldType:New') }, { value: 'Conversion', label: t('scaffoldType:Change') }],
    //   },
    //   width: 85,
    // },
    // {
    //   type: 'autocomplete',
    //   name: 'scaffoldReferenceId',
    //   additionalProps: {
    //     options: scaffoldOptionsActive,
    //   },
    //   width: 167,
    // },
    {
      type: 'text',
      name: 'description',
      required: true,
      additionalProps: {
        maxLength: 100,
      },
      width: 250,
    },
    {
      type: 'autocomplete',
      name: 'orderedByUserId',
      additionalProps: {
        options: userOptions,
      },
      width: 250,
      required: true,
    },
    {
      type: 'object',
      name: 'individualFields',
      children: (customer?.individualScaffoldOrderFields ? customer?.individualScaffoldOrderFields.map((field:CustomerSpecificField) => ({
        type: resolveIndividualFieldType(field.type),
        name: field.id,
        label: field.name,
        // @ts-ignore
        required: field.required === 'ALWAYS' || field.required === ScaffoldState.DRAFT,
        additionalProps: {

        },
      })) : []),

    },

    {
      type: 'object',
      name: 'clientWorkflowStatus',
      children: (customer?.individualScaffoldOrderFields ? customer?.scaffoldWorkflow.map((field:ApproverStep) => ({
        type: 'object',
        name: field.id,
        children: [
          {
            type: 'autocomplete',
            name: 'userId',
            label: field.name,
            additionalProps: {
              options: primaryUserOptions,
            },
            required: true,
            width: 300,
          },
        ],
      })) : []),
    },

  ] as IExternalColumn<ScaffoldDraftModel>[], [buildings, customer?.individualScaffoldOrderFields, customer?.scaffoldWorkflow, operationUnitOptions, operationUnits, primaryUserOptions, projectOptions, synchronization, i18n.language, userOptions])
  const open = useMemo(() => state.value.map((x:any, idx:number) => ({ index: idx, value: x })).filter((x: { value: { selected: any } }) => x.value.selected), [state])

  return (
    <SpreadSheet<ScaffoldDraftModel >
      state={state}
        // onRowChanged={handleRowChanged}
      columns={columns}
      actions={{
          open: open.length > 0,
          renderActions: SelectionTools,
        }}
      t={t}
    />

  )
}

export default function ScaffoldInquirySpreadsheetEditor() {
  const customer = useCustomerByPath()
  const user = useCurrentUser()

  const { t } = useTranslation('scaffolds')

  if (!customer) return (<LoadingCircle />)
  return (
    <div>
      <BreadCrumb
        color="black"
        linkStack={[
        {
          to: '..',
          text: t('customerId'),
        },
        {
          text: customer.name,
        },
      ]}
      />
      <Table user={user} customer={customer} />

    </div>

  )
}
