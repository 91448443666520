import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useCustomer } from 'features/customers'
import { useParams } from 'react-router'
import { actions, useBuildingByPath } from 'features/buildings'
import EditView from '../../../../../framework/components/BaseRestViews/EditView'
import { BuildingModel } from '../../../../../api/models/buildingModel'
import { DeleteEnabled } from '../../../SaveView/DeleteEnabled'

const bigGrid = { xs: 12, sm: 6 }
const grid = { xs: 12, sm: 6, md: 3 }
const smallGrid = { xs: 6, sm: 3, md: 2 }

export default function Editor({ item }: { item?: BuildingModel }) {
  const { customerId, buildingId } = useParams<{ customerId: string, buildingId: string }>()
  const customer = useCustomer(customerId!)
  const { t } = useTranslation('buildings')
  const { t: tGeneric } = useTranslation('generic')
  const elements = useMemo(() => ([
    {
      type: 'text',
      required: true,
      grid: bigGrid,
      name: 'name',
    },
    {
      type: 'text',
      grid: bigGrid,
      required: true,
      name: 'description',
    },
    {
      type: 'text',
      maxRows: 3,
      multiline: true,
      name: 'routeDescription',
    },
    {
      type: 'object',
      variant: 'none',
      name: 'address',
      elements: [
        {
          type: 'text',
          name: 'street1',
          grid,
        },
        {
          type: 'text',
          name: 'street2',
          grid,
        },
        {
          type: 'text',
          name: 'zip',
          grid: smallGrid,

        },
        {
          type: 'text',
          name: 'city',
          grid: smallGrid,
        },
        {
          type: 'text',
          name: 'country',
          grid: smallGrid,
        },
      ],
    },
    {
      type: 'checkbox',
      grid: smallGrid,
      name: 'archived',
      label: tGeneric('archived'),
      // disabled: true,
    },
    // {
    //   type: 'autocomplete',
    //   name: 'buildings',
    //   multi: true,
    //   options: buildingOptions,
    // },
  ]), [])
  const linkStack = useMemo(() => ([
    { to: '/administration/customers', text: t('application:customers') },
    { to: `/administration/customers/${customer?.id}`, text: `${customer?.name}` },
    { to: `/administration/customers/${customer?.id}/buildings`, text: t('application:buildings') },
    { text: item?.isNew ? t('application:new') : `${item?.name}` },
  ]), [t, customer, item])
  return (
    <EditView
      loading={!customer || !item}
      linkStack={linkStack}
      item={item}
      elements={elements}
      actions={actions}
      saveView={{
        hideDraft: true,
        // enableArchive: true,
        enableDelete: DeleteEnabled.Always,
      }}
      t={t}
    />
  )
}

export function Edit() {
  const item = useBuildingByPath('buildingId')

  return (
    <Editor item={item} />
  )
}

export function New() {
  const { customerId } = useParams() as { customerId: string }
  const item = new BuildingModel(customerId)
  return (
    <Editor item={item} />
  )
}
