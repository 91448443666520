import { INavItemWithChildren } from 'models/INavItem'
import PermissionModel from 'models/PermissionModel'
import { unreadCountSelectorSiteMeasuring } from 'ui/views/SiteMeasurings/helper'
import { unreadCountSelectorSiteMeasuringInspection } from 'features/siteMeasuringInspections'
import { unreadCountSelectorScaffoldInquiries, unreadCountSelectorScaffolds } from 'features/scaffoldInquiries'
import { PermissionOperation } from 'framework/auth/permissionOperation'
import { Home } from '@mui/icons-material'
import { PlaylistEdit } from 'mdi-material-ui'

const items: INavItemWithChildren[] = [
  {
    target: '/dashboard',
    key: 'home',
    matchesRoute: (location) => location.startsWith('/dashboard'),
    icon: <Home />,
    small: true,
    unreadCountSelector: () => 0,
    requireLogin: true,
  },
  {
    target: '/multiScaffoldInquiriesEditor',
    key: 'multiScaffoldEditor',
    matchesRoute: (location) => location.startsWith('/multiScaffoldInquiriesEditor'),
    icon: <PlaylistEdit />,
    small: true,
    requirePermissions: { operation: PermissionOperation.AND, permissions: [PermissionModel.VIEW_MY_CUSTOMER, PermissionModel.EDIT_MY_SCAFFOLD_REQUESTS] },
    unreadCountSelector: () => 0,
    requireLogin: true,
  },
  {
    target: '/scaffoldInquiries',
    key: 'scaffoldInquiries',
    matchesRoute: (location) => location.startsWith('/scaffoldInquiries') && location !== '/scaffoldInquiries/new',
    unreadCountSelector: unreadCountSelectorScaffoldInquiries,
    requireLogin: true,
  },
  {
    target: '/scaffolds',
    key: 'scaffolds',
    matchesRoute: (location) => location.startsWith('/scaffolds'),
    unreadCountSelector: unreadCountSelectorScaffolds,
    requireLogin: true,
    childrenOnlyWhenRouteMatches: true,
    children: [
      {
        target: '/scaffolds',
        key: 'last12Months',
        matchesRoute: (location) => location.startsWith('/scaffolds') && !location.startsWith('/scaffolds/archive'),
        unreadCountSelector: () => 0,
      },
      {
        target: `/scaffolds/archive/${new Date().getFullYear() - 1}`,
        key: 'archive',
        matchesRoute: (location) => location.startsWith('/scaffolds/archive'),
        unreadCountSelector: () => 0,
      },
    ],
  },
  {
    target: '/siteMeasuring',
    key: 'siteMeasuring',
    matchesRoute: (location) => location.startsWith('/siteMeasuring') && !location.startsWith('/siteMeasuringInspection'),
    unreadCountSelector: unreadCountSelectorSiteMeasuring,
    requirePermissions: {
      operation: PermissionOperation.OR,
      permissions: [PermissionModel.VIEW_ALL_SITE_MEASURING, PermissionModel.VIEW_MY_SITE_MEASURING],
    },
    childrenOnlyWhenRouteMatches: true,
    children: [
      {
        target: '/siteMeasuring',
        key: 'last12Months',
        matchesRoute: (location) => location.startsWith('/siteMeasuring') && !location.startsWith('/siteMeasuring/archive'),
        unreadCountSelector: () => 0,
        requirePermissions: {
          operation: PermissionOperation.OR,
          permissions: [PermissionModel.VIEW_ALL_SITE_MEASURING, PermissionModel.VIEW_MY_SITE_MEASURING],
        },
      },
      {
        target: `/siteMeasuring/archive/${new Date().getFullYear() - 1}`,
        key: 'archive',
        matchesRoute: (location) => location.startsWith('/siteMeasuring/archive'),
        unreadCountSelector: () => 0,
        requirePermissions: {
          operation: PermissionOperation.OR,
          permissions: [PermissionModel.VIEW_ALL_SITE_MEASURING, PermissionModel.VIEW_MY_SITE_MEASURING],
        },
      },
    ],
  },
  {
    target: '/siteMeasuringInspection',
    key: 'siteMeasuringInspection',
    matchesRoute: (location) => location.startsWith('/siteMeasuringInspection'),
    unreadCountSelector: unreadCountSelectorSiteMeasuringInspection,
    // requiredPermissions: [PermissionModel.VIEW_ALL_SITE_MEASURING],
    requirePermissions: {
      operation: PermissionOperation.AND,
      permissions: [PermissionModel.VIEW_ALL_SITE_MEASURING],
    },
    childrenOnlyWhenRouteMatches: true,
    children: [
      {
        target: '/siteMeasuringInspection',
        key: 'last12Months',
        matchesRoute: (location) => location.startsWith('/siteMeasuringInspection') && !location.startsWith('/siteMeasuringInspection/archive'),
        unreadCountSelector: () => 0,
        requirePermissions: {
          operation: PermissionOperation.AND,
          permissions: [PermissionModel.VIEW_ALL_SITE_MEASURING],
        },
      },
      {
        target: `/siteMeasuringInspection/archive/${new Date().getFullYear() - 1}`,
        key: 'archive',
        matchesRoute: (location) => location.startsWith('/siteMeasuringInspection/archive'),
        unreadCountSelector: () => 0,
        requirePermissions: {
          operation: PermissionOperation.AND,
          permissions: [PermissionModel.VIEW_ALL_SITE_MEASURING],
        },
      },
    ],
  },
  {
    target: '/administration/myprojects',
    key: 'myProjects',
    matchesRoute: (location) => location.startsWith('/administration/myprojects'),
    unreadCountSelector: () => 0,
    requirePermissions: {
      operation: PermissionOperation.AND,
      permissions: [
        PermissionModel.EDIT_PROJECTS_MY_CUSTOMER,
        {
          operation: PermissionOperation.NOT,
          permissions: [PermissionModel.VIEW_ALL_CUSTOMERS],
        },
      ],
    },
  },
  {
    target: '/administration',
    key: 'administration',
    matchesRoute: (location) => location.startsWith('/administration'),
    requirePermissions: {
      operation: PermissionOperation.OR,
      permissions: [PermissionModel.EDIT_ALL_CUSTOMERS, PermissionModel.VIEW_ALL_CUSTOMERS, PermissionModel.EDIT_SITE_MEASURING_TEMPLATES, PermissionModel.VIEW_USERS],
    },
    unreadCountSelector: () => 0,
    children: [
      {
        target: '/administration/customers',
        key: 'customers',
        matchesRoute: (location) => location.startsWith('/administration/customers'),
        unreadCountSelector: () => 0,
        requirePermissions: {
          operation: PermissionOperation.AND,
          permissions: [PermissionModel.VIEW_ALL_CUSTOMERS],
        },
      },
      {
        target: '/administration/siteMeasuringTemplates',
        key: 'siteMeasuringTemplates',
        matchesRoute: (location) => location.startsWith('/administration/siteMeasuringTemplates'),
        unreadCountSelector: () => 0,
        requirePermissions: {
          operation: PermissionOperation.AND,
          permissions: [PermissionModel.EDIT_SITE_MEASURING_TEMPLATES],
        },
      },
      {
        target: '/administration/scaffolders',
        key: 'scaffolders',
        matchesRoute: (location) => location.startsWith('/administration/scaffolders'),
        unreadCountSelector: () => 0,
        requirePermissions: {
          operation: PermissionOperation.AND,
          permissions: [PermissionModel.VIEW_ALL_CUSTOMERS],
        },
      },
      {
        target: '/administration/users',
        key: 'users',
        matchesRoute: (location) => location.startsWith('/administration/users'),
        unreadCountSelector: () => 0,
        requirePermissions: {
          operation: PermissionOperation.AND,
          permissions: [PermissionModel.VIEW_USERS],
        },
      },
    ],
  },
  {
    target: '/scaffoldInquiries/new',
    key: 'newScaffold',
    matchesRoute: (location) => location.startsWith('/scaffolds/new'),
    unreadCountSelector: () => 0,
    requirePermissions: {
      operation: PermissionOperation.AND,
      permissions: [PermissionModel.PLACE_ORDER_SCAFFOLD],
    },
  },
]
export default items
