/* eslint-disable */
import ScaffoldInquiryRestModel from "api/scaffoldInquiries";
import LoadStateModel from "../framework/LoadStateModel";
import {IBaseRestState} from "features/base/BaseRestFeature";
import {
  getScaffoldPhase,
  IScaffoldScaffolderRequest,
  ScaffoldAgentPhase,
  ScaffoldInquiryModel
} from "../api/models/scaffoldModel";
import {AppThunk, RootState, store} from "../app/store";
import {IBaseModel, IUpdateResult} from "../api/baseModel";
import i18n from "i18next";
import {displayNotification, displayNotificationTranslated} from "./notifications";
import {useSelector} from "react-redux";
import {useMemo} from "react";
import fileDownload from "framework/lib/fileDownload";
import {getUser} from "features/user";
import {userHasAllPermissions} from "framework/auth/permissions";
import PermissionModel from "models/PermissionModel";

import {createNamedBaseRestSlice} from "features/base/creators/CreateNamedBaseRestSlice";
import { IValidatedFormAutocompleteOptions } from '@codeflix/mui-managed-form'


type DataType = ScaffoldInquiryModel
const key = 'scaffoldInquiries'

const initialState: IBaseRestState<DataType> = {
  data: [],
  loadState: LoadStateModel.INITIAL,
  archive: {},
}
export const {actions,index,hooks,selectors} = createNamedBaseRestSlice<DataType>(
  key,
  initialState,
  {},
  ScaffoldInquiryRestModel
)
// const { get, update,create, deleteItem } = actions
const { create,update} = index.actions as any

export const updateItemScaffolder = (data:ScaffoldInquiryModel): AppThunk => async (dispatch, getState) => {
  try {
    const updateData:IScaffoldScaffolderRequest = {
      scaffoldInquiryId: data.id,
      scaffold: data.scaffold,
      constructionDate: data.constructionDate,
      deconstructionDate: data.deconstructionDate,
      loadClass: data.loadClass,
      sign: data.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED,
      singleLoadTonnes: data.singleLoadTonnes,
      widthLoad: data.widthLoad
    }

    const response = await ScaffoldInquiryRestModel.updateItemScaffolder(updateData) as IBaseModel | IUpdateResult
    if (response) {
      dispatch(update((response as IUpdateResult)?.isAcknowledged ? data : response as IBaseModel))
      const message = `${i18n.t('api:saveSuccess')}`
      const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
      store.dispatch(snackbar)
      return true
    }
    return false
  } catch (e) {
    //
    return false
  }
}
export const cancelScaffolds = (data: ScaffoldInquiryModel[],date: Date) :AppThunk => async (dispatch, getState) => {
  try {
    const newPayload = await ScaffoldInquiryRestModel.cancelScaffolds(data,date)
    newPayload.forEach(it=>dispatch(update(it)))
    const message = `${i18n.t('api:saveSuccess')}`
    const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
    store.dispatch(snackbar)
    return true;

  }
  catch(e){
    return false;
  }
}

export const deleteScaffoldForced = (data: ScaffoldInquiryModel) :AppThunk => async (dispatch, getState) => {
  try {
    const newPayload = await ScaffoldInquiryRestModel.deleteForced(data)
    // newPayload.forEach(it=>dispatch(update(it)))
    dispatch(actions.deleteItem(data))
    const message = `${i18n.t('api:saveSuccess')}`
    const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
    store.dispatch(snackbar)
    return true;

  }
  catch(e){
    return false;
  }
}

export const deconstructScaffolds = (data: ScaffoldInquiryModel[],date: Date) :AppThunk => async (dispatch, getState) => {
  try {
    const newPayload = await ScaffoldInquiryRestModel.deconstructScaffolds(data,date)
    newPayload.forEach(it=>dispatch(update(it)))
    const message = `${i18n.t('api:saveSuccess')}`
    const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
    store.dispatch(snackbar)
    return true;
  }
  catch(e){
    return false;
  }
}
export const constructScaffolds = (data: ScaffoldInquiryModel[],date: Date) :AppThunk => async (dispatch, getState) => {
  try {
    const newPayload = await ScaffoldInquiryRestModel.constructScaffolds(data,date)
    newPayload.forEach(it=>dispatch(update(it)))
    const message = `${i18n.t('api:saveSuccess')}`
    const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
    store.dispatch(snackbar)
    return true;
  }
  catch(e){
    return false;
  }
}
export const cloneScaffolds = (data: ScaffoldInquiryModel[]) :AppThunk => async (dispatch, getState) => {
  try {
    const newPayload = await ScaffoldInquiryRestModel.cloneScaffolds(data)
    newPayload.forEach(it=>dispatch(create(it)))
    return newPayload
  }
  catch(e){
    return null;
  }
}
export const convertScaffolds = (data: ScaffoldInquiryModel[]) :AppThunk => async (dispatch, getState) => {
  try {
    const newPayload = await ScaffoldInquiryRestModel.convert(data)
    newPayload.forEach(it=>dispatch(create(it)))
    return newPayload
  }
  catch(e){
    return null;
  }
}
export const revertCanceledDate  = (data: ScaffoldInquiryModel) :AppThunk => async (dispatch, getState) => {
  try {
    if(data.deconstructionDate){
      const message = `${i18n.t('api:SCAFFOLD_REVERT_CANCELED_DATE_IMPOSSIBLE')}`
      const snackbar = displayNotification(message, { variant: 'error', autoClose: true })
      store.dispatch(snackbar)
      return
    }
    const newPayload = await ScaffoldInquiryRestModel.revertCanceledDate(data)
    dispatch(update(newPayload))
    const message = `${i18n.t('api:saveSuccess')}`
    const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
    store.dispatch(snackbar)
    return newPayload
  }
  catch(e){
    return null;
  }
}

export const restartClientWorkflow  = (data: ScaffoldInquiryModel) :AppThunk => async (dispatch, getState) => {
  try {
    if(data.agentPhase !== ScaffoldAgentPhase.CLIENT){
      const message = `${i18n.t('api:SCAFFOLD_RESET_CLIENT_WORKFLOW_NOT_POSSIBLE_ANYMORE')}`
      const snackbar = displayNotification(message, { variant: 'error', autoClose: true })
      store.dispatch(snackbar)
      return
    }
    const newPayload = await ScaffoldInquiryRestModel.restartClientWorkflow(data)
    dispatch(update(newPayload))
    const message = `${i18n.t('api:saveSuccess')}`
    const snackbar = displayNotification(message, { variant: 'success', autoClose: true })
    store.dispatch(snackbar)
    return newPayload
  }
  catch(e){
    return null;
  }
}
export const createScaffoldCertificate =  (payload: ScaffoldInquiryModel, withAttachments: boolean):AppThunk =>async (dispatch, getState) => {
  try{
    const pdfJobId = await ScaffoldInquiryRestModel.createCertificate(payload,withAttachments)
    const downloadToken = await ScaffoldInquiryRestModel.getCertificateDownloadToken(pdfJobId as string)
    const url = `${window.apiUrl}/scaffoldinquiries/${pdfJobId}/certificate?token=${downloadToken}`

    if(downloadToken) fileDownload(url)

  }
  catch(e) {
    store.dispatch(displayNotificationTranslated('generic:error'))
    console.error('createScaffoldCertificate',e)
  }
}

export const createScaffoldSiteMeasuringPdf =  (payload: ScaffoldInquiryModel):AppThunk =>async (dispatch, getState) => {
  try{
    const pdfJobId = await ScaffoldInquiryRestModel.createSiteMeasuringPdf(payload)
    const downloadToken = await ScaffoldInquiryRestModel.getSiteMeasuringPdfoken(pdfJobId as string)
    const url = `${window.apiUrl}/scaffoldinquiries/${pdfJobId}/siteMeasuringPdf?token=${downloadToken}`

    if(downloadToken) fileDownload(url)

  }
  catch(e) {
    store.dispatch(displayNotificationTranslated('generic:error'))
    console.error('createScaffoldCertificate',e)
  }
}


export const useScaffoldByPath = () => hooks.useByPathKey('scaffoldInquiryId')

export const useScaffoldInquiryById = hooks.useById
export const useScaffoldInquiries = hooks.useAll

export const useOpenScaffoldInquiries = ()=> {
  const data = hooks.useAll()
  return useMemo(()=>data?.filter((x: { agentPhase: ScaffoldAgentPhase; })=>x.agentPhase !== ScaffoldAgentPhase.FULLY_SIGNED),[data])
}
export const useScaffoldInquiriesFiltered = hooks.useFiltered

export const useCurrentScaffolds = ()=> {
  const data = hooks.useAll() as ScaffoldInquiryModel[]
  return data?.filter(x=>x.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED)
  // return useMemo(()=>data?.filter(x=>x.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED),[data])
}
export const useScaffoldOptions = (customerId?: string):IValidatedFormAutocompleteOptions[] => {
  const data = useSelector(selectors.all()) as ScaffoldInquiryModel[]
  return useMemo(() => data.filter(x=>!x.deconstructionDate && customerId? x.customerId === customerId : true).map((x) => ({
    label: x.serialNumber?.fullSerial||'',
    value: x.id,
  })), [data,customerId])
}

export const unreadCountSelectorScaffoldInquiries = (state: RootState) => {
  const agentPhases: ScaffoldAgentPhase[] = []
  let count = 0;
  const currentUser = getUser(state)
  let scaffoldInquiries = state.scaffoldInquiries.data
  if (userHasAllPermissions(currentUser, [PermissionModel.PLACE_ORDER_SCAFFOLD])) {
    // is client
    // todo: should we evaluate the possible user assignment?
    const assignedScaffolds = state.scaffoldInquiries.data.filter(
      x=>x.agentPhase === ScaffoldAgentPhase.CLIENT &&
        (
        (x.clientWorkflowPhase === -1 && x.orderedByUserId === currentUser?.id)
        || (
            getScaffoldPhase(x,undefined)?.currentPhase?.userId === currentUser?.id
          ))
    )
    count+=assignedScaffolds.length
    // agentPhases.push(ScaffoldAgentPhase.CLIENT)
  }
  if (userHasAllPermissions(currentUser, [PermissionModel.TRANSMIT_SCAFFOLD_REQUEST_TO_SCAFFOLDER])) {
    // is gdk
    agentPhases.push(ScaffoldAgentPhase.GDK)
  }
  if (userHasAllPermissions(currentUser, [PermissionModel.CREATE_SITE_MEASURING_FOR_SCAFFOLD_REQUEST])) {
    // is scaffolder
    agentPhases.push(ScaffoldAgentPhase.SCAFFOLDER)
  }
  count += state.scaffoldInquiries.data.filter((x) =>
    agentPhases.includes(x.agentPhase)
  ).length
  return count
}
export const unreadCountSelectorScaffolds = (state: RootState) => {
  return state.scaffoldInquiries.data.filter((x) => x.agentPhase === ScaffoldAgentPhase.FULLY_SIGNED).length

}
export default index.reducer
